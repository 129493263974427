import React from "react"
import { Link } from "gatsby"

import "./index.scss"

const CalloutBlock = ({
  className,
  backgroundColor,
  iconName,
  bg,
  icon,
  link,
  children,
}) => {
  return (
    <Link to={link} className="callout-link">
      <div
        className={`calloutblock-content calloutblock-${className}`}
        style={{ backgroundColor: `${backgroundColor}` }}
      >
        <img src={bg} className={`bg-cover`} />
        <img src={icon} className={`${iconName}-icon icon`} />
        {children}
      </div>
    </Link>
  )
}

export default CalloutBlock
