import React from "react"
import { Row, Col } from "react-flexbox-grid"
import "./index.scss"
import Hero from "../../../components/Hero"
import Layout from "../../../components/Layout"
import ContentBlock from "../../../components/ContentBlock"
import HelmetWrapper from "../../../components/HelmetWrapper"
import CalloutBlock from "../../../components/CalloutBlock"

import grantCircle from "../../../assets/images/content-iss.png"
import headerHero from "../../../assets/images/header_iss.jpg"
import arrow from "../../../assets/images/chevron-circle-right-solid.svg"
import pdf from "../../../../static/static/ConceptSubmissionForm.pdf"

const pageTitle = "Investigator Sponsored Studies | Harmony Biosciences"
const pageDescription =
  "Investigator Sponsored Studies (ISS) can help advance disease-related knowledge and may contribute towards improved patient care. Learn more and apply."
const pageKeywords = ""
const smallScreenSize = () => {
  const _screenSize = window ? window.screen.width : 0
  return _screenSize <= 768 ? 66 : 155
}
class grantPrograms extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      offsetSize: 0,
    }
  }
  componentDidMount() {
    //alert(smallScreenSize())
    //console.log('hey')
    //console.log(screenSize())
    this.setState({
      offsetSize: smallScreenSize(),
    })
  }
  render() {
    return (
      <Layout jobCode="US-HAR-2200050/Jun 2022">
        <div id="investigator-sponsored-studies">
          <HelmetWrapper
            pageTitle={pageTitle}
            pageDescription={pageDescription}
            pageKeywords={pageKeywords}
          />
          <Hero bg={headerHero}>
            <h1>Investigator Sponsored Studies</h1>
          </Hero>

          <ContentBlock color="gray" id="iss">
            <Row>
              <Col xs={12} md={2}>
                <Row center="xs">
                  <Col>
                    <img src={grantCircle} className="" />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={10}>
                <h2>Investigator Sponsored Studies (ISS) Program</h2>
                <p>
                  <strong>Harmony Biosciences</strong> supports Investigator
                  Sponsored Studies (ISS) aimed at furthering the understanding
                  of our products and that align with our areas of scientific
                  interest. Such studies may advance disease-related knowledge,
                  improve patient care, or explore unmet clinical needs.
                </p>
                <p>
                  These research studies are initiated and conducted by external
                  investigators with the appropriate (or relevant) scientific
                  background and experience to conduct an independent scientific
                  investigation. Investigator Sponsored Studies may be, but are
                  not limited to, interventional (clinical and non-clinical),
                  observational (epidemiological or outcomes) or survey studies.
                </p>
                <p>
                  The external sponsor-investigator assumes responsibility for
                  the legal and regulatory requirements of the trial and the
                  conduct and management of the study as defined by applicable
                  laws, regulations, and guidance documents (e.g. 21 CFR Part
                  312, Good Clinical Practice (GCP), International Conference on
                  Harmonization (ICH), and Good Pharmacovigilance Practices
                  (GVP)).
                </p>
                <p>
                  The study proposal, outline, and protocol must be designed and
                  written by the investigator, independent of Harmony
                  Biosciences’ influence.
                </p>
                <p>
                  <strong>
                    The Harmony Investigator Sponsored Study submission process
                    includes two phases:
                  </strong>
                </p>
                <ol className="relative ml-25">
                  <li className="first">
                    Phase one requires a requestor to create an account and
                    submit their research concept through the{" "}
                    <a
                      href="https://harmonybiosciences-iss.steeprockinc.com/"
                      target="_blank"
                    >
                      Harmony ISS Portal
                    </a>
                    . ISS concepts are then reviewed by the Harmony research
                    grants committee. After a review has been completed, the
                    investigator will be notified of the decision.
                  </li>
                  <li className="second">
                    If the concept demonstrates scientific rigor and aligns with
                    Harmony’s areas of scientific interest, the requestor will
                    be required to initiate phase two by submitting a full
                    protocol and detailed budget. The full protocol should
                    include a rationale, comprehensive study design, defined
                    plan for data capture and analysis, adverse event reporting,
                    and a plan for public dissemination of data. After committee
                    review, the requestor will be notified of a final decision.
                  </li>
                </ol>
                <p>
                  <strong>Areas of Scientific Interest:</strong>
                </p>
                <ul className="ml-25">
                  <li>
                    The impact of pitolisant on functional outcomes and/or
                    quality of life in patients with narcolepsy
                  </li>
                  <li>
                    The effectiveness of pitolisant in diseases which excessive
                    daytime sleepiness (EDS) and/or fatigue are common and
                    debilitating symptoms
                  </li>
                  <li>
                    The role of histamine, the H<sub>3</sub> receptor, and the
                    impact of pitolisant on histaminergic neurotransmission,
                    including the effect on other neurotransmitter systems
                  </li>
                  <li>
                    Exploratory research regarding the effect of pitolisant in
                    other central disorders of hypersomnolence
                  </li>
                  <li>
                    The effectiveness of pitolisant on impairments of attention,
                    vigilance, and/or cognition
                  </li>
                  <li>
                    Improvements in recognition of EDS, the diagnosis of
                    narcolepsy, or evaluation of sleepiness as a comorbid
                    symptom in other disease states
                  </li>
                </ul>

                <p>
                  For any questions, please email{" "}
                  <a
                    href="mailto:grants@harmonybiosciences.com"
                    target="_blank"
                  >
                    grants@harmonybiosciences.com
                  </a>
                  .
                </p>
                <p class="footnote-small">
                  Harmony does not provide grants to individuals unless an
                  individual is acting as a sponsor investigator of an
                  Investigator Sponsored Study.
                </p>
                <p class="footnote-small">
                  Any funding provided by Harmony will be in compliance with all
                  applicable laws, rules, and regulations.
                </p>
              </Col>
            </Row>
          </ContentBlock>

          <Row className={"callout-row"}>
            <Col xs={12} md={6}>
              <CalloutBlock
                className="text-only bg-blue"
                link="/funding-programs/patients-at-the-heart"
              >
                <h2>PATIENTS AT THE HEART</h2>
                <img className="callout-arrow" src={arrow} />
              </CalloutBlock>
            </Col>
            <Col xs={12} md={6}>
              <CalloutBlock
                className="text-only bg-rose"
                link="/funding-programs/progress-at-the-heart"
              >
                <h2>PROGRESS AT THE HEART</h2>
                <img className="callout-arrow" src={arrow} />
              </CalloutBlock>
            </Col>
            <Col xs={12} md={6}>
              <CalloutBlock
                className="text-only bg-orange gap"
                link="/funding-programs/external-funding"
              >
                <h2>EXTERNAL FUNDING</h2>
                <img className="callout-arrow" src={arrow} />
              </CalloutBlock>
            </Col>
            <Col xs={12} md={6}>
              <CalloutBlock
                className="text-only bg-green gap"
                link="/funding-programs/medical-education-grants"
              >
                <h2>MEDICAL EDUCATION GRANTS</h2>
                <img className="callout-arrow" src={arrow} />
              </CalloutBlock>
            </Col>
          </Row>
        </div>
      </Layout>
    )
  }
}

export default grantPrograms
